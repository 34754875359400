import Button, { BlueButton } from "@/components/fields/button";
import LabelInput from "@/components/fields/input";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { useState } from "react";
import { signIn } from "next-auth/react";

import styles from "styles/login.module.css";
import miscStyles from "styles/misc.module.css";
import IsLoggedOutWrapper from "@/components/IsLoggedOutWrapper";
import clsx from "clsx";
import CryptoFooter from "@/components/Footer/CryptoFooter";
import { StoreContext } from "@/context";
import { getAuthCode } from "@/services/auth";

export default function Login() {
  const router = useRouter();
  const context = useContext(StoreContext);
  const authAction = context.actions.auth;
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("Login");

  return (
    <IsLoggedOutWrapper>
      <div>
        <div className={styles.loginContent}>
          <div className={styles.titleGroup}>
            <img
              src="/images/cslogo.svg"
              alt="CryptoSports"
              className={styles.logoSvg}
            />
            <h4 className={miscStyles.tagline}>Collect. Play. Win.</h4>
          </div>

          <div className={styles.formFields}>
            <LabelInput
              label="Email"
              placeholder="Enter Email"
              value={email}
              onChange={setEmail}
              inputOptions={{
                className: styles.input,
              }}
            />
            <LabelInput
              label="Password"
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={setPassword}
              inputOptions={{
                className: styles.input,
              }}
            />
          </div>

          <div className={styles.buttonContainer}>
            {error && <p className={styles.error}>{error}</p>}
            <BlueButton
              text={message}
              disabled={!email || !password}
              onClick={async () => {
                setMessage("Please wait...");
                let res = await signIn(
                  // "cognito",
                  "credentials",
                  {
                    email: email,
                    password,
                    callbackUrl: `${window.location.origin}/home`,
                    redirect: false,
                  } /*  */
                );

                if (res.error) {
                  if (res.error === "User not confirmed.") {
                    authAction.setEmail(email);
                    authAction.setPassword(password);
                    router.push(`/signup/confirm/${email}?cs=true`);
                  } else {
                    console.log("rwa", res);
                    setError(res.error);
                    setMessage("Login");
                  }
                }
                // router.push("/home");
              }}
              className={styles.loginButton}
            />
            {/* <BlueButton
              className={clsx(styles.loginButton, styles.googleSignupButton)}
              onClick={() => {
                signIn("cognito");
              }}
              text="Sign in with Google"
              iconSrc="/images/icons/google.png"
            /> */}
          </div>
          <div className={styles.instructionContainer}>
            <p className={styles.instruction}>
              {"Don't have an account? "}
              <Link href={"/signup"}>
                <span className={styles.link}>Sign up</span>
              </Link>
            </p>
            <p className={styles.instruction}>
              <Link href={"/reset-password"}>
                {/* <span className={styles.link}> */}
                <b className={styles.reset}>Forgot password?</b>
                {/* </span> */}
              </Link>
            </p>
          </div>
        </div>
      </div>

      <CryptoFooter />
    </IsLoggedOutWrapper>
  );
}
